p > div {
  display: none;
}

.cookie-consent-banner {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  z-index: 2147483645;
  box-sizing: border-box;
  width: 100%;

  background-color: #f1f1f1;
}

.cookie-consent-banner__inner {
  display: flex;
  max-width: 100vw;
  margin: 0 auto;
  padding: 32px 32px;
}

.cookie-consent-banner__copy {
  margin-bottom: 16px;
}

.cookie-consent-banner__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
}

.cookie-consent-banner__header {
  margin-bottom: 8px;

  font-family: "CeraPRO-Bold", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__description {
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  text-align: justify;
  color: #838f93;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  min-width: 164px;
  padding: 9px 13px;

  border-radius: 2px;

  background-color: #2c80e0;

  color: #fff;
  text-decoration: none;
  text-align: center;
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.cookie-consent-banner__cta--secondary {
  padding: 9px 13px;

  border: 2px solid #3a4649;

  background-color: transparent;

  color: #2653d0;
}

.cookie-consent-banner__cta:hover {
  background-color: #3386d8;
}

.cookie-consent-banner__cta--secondary:hover {
  border-color: #838f93;

  background-color: transparent;

  color: #213ac7;
}

.cookie-consent-banner__cta:last-child {
  margin-left: 16px;
}

.cookie-consent-banner--hidden {
  bottom: -100%; /* Adjust based on the actual height of the banner */
  transition: bottom 0.5s ease-in-out;
}

.markdown pre {
  overflow: auto;
}

.markdown * {
  word-wrap: break-word;
}

.markdown ol,
.markdown ul {
  padding-left: 1.5rem;
}

span:has(> .select-trigger-is) {
  display: flex;
  width: 100%;
}
